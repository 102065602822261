import { Modal } from 'antd';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export const DemoSteps = ({ children, demoSteps, demoStepsClass }) => {
	const [stepIndexPulse, setStepIndexPulse] = useState(0);
	const [stepSelected, setStepSelected] = useState(demoSteps[0]);
	const [showStepModal, setShowStepModal] = useState(false);

	const openStepModal = (index) => {
		setStepSelected(demoSteps[index]);
		setShowStepModal(true);
		setStepIndexPulse(index !== demoSteps.length - 1 ? index + 1 : index);
	};

	return (
		<div className="browser-image">
			{children}
			<div className={`browser-image__buttons ${demoStepsClass}`}>
				{demoSteps.map((item, index) => {
					return (
						<button
							key={index}
							onClick={() => openStepModal(index)}
							className={`button-icon button-icon--number ${
								index === stepIndexPulse ? 'button-icon--number--pulse' : ''
							}`}
						>
							{item.order}
						</button>
					);
				})}

				<Modal
					centered
					onCancel={() => setShowStepModal(false)}
					visible={showStepModal}
					title={
						<>
							<h4>{stepSelected.title}</h4>
							<ReactMarkdown children={stepSelected.description} rehypePlugins={[rehypeRaw]} />
						</>
					}
					width={'100%'}
					footer={null}
					className="ant-modal--green"
				>
					<GatsbyImage
						alt={stepSelected.icon?.alternativeText || stepSelected.image.alternativeText}
						image={getImage(stepSelected.icon?.localFile || stepSelected.image.localFile)}
					/>
				</Modal>
			</div>
		</div>
	);
};
