import { Player } from '@lottiefiles/react-lottie-player';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export const SectionTools = ({ tools, title }) => {
    const [toolSelected, setToolSelected] = useState(tools[0])
    const [toolInterval, setToolInterval] = useState(true)

    const nextTool = (allowInterval = true) => {
        const index = tools.indexOf(toolSelected)

        if (index === (tools.length - 1)) {
            setToolSelected(tools[0])
        } else {
            setToolSelected(tools[index + 1])
        }

        setToolInterval(allowInterval)
    }

    const prevTool = () => {
        const index = tools.indexOf(toolSelected)

        if (index === 0) {
            setToolSelected(tools[tools.length - 1])
        } else {
            setToolSelected(tools[index - 1])
        }
    }

	return (
		<div id="section-tools" className="main__section main__section--50 main__section--50--md">
			<div className="container">
				<div className="container__50">
					<div className="carrousel carrousel--slim">
						{tools.map((item, index) => (
							<div
								className={`carrousel__content ${
									toolSelected.id === item.id ? 'carrousel__content--show' : ''
								}`}
								key={index}
							>
								<h2>
									{title}{' '}
									<span className="typewriter" style={{ '--n': [...item.title].length }}>
										{item.title}
									</span>
								</h2>
								<ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
							</div>
						))}
						<div className="carrousel__actions">
							<div className="carrousel__actions__dots">
								{tools.map((item, index) => (
									<span
										className={`dots__dot ${
											item.id === toolSelected.id ? 'dots__dot--active' : ''
										}`}
										key={index}
										onClick={() => setToolSelected(item)}
									/>
								))}
							</div>
							<div className="carrousel__actions__buttons">
								<button
									className="button-icon button-icon--secondary button-icon--lg"
									onClick={prevTool}
								>
									<i className="icon-arrow-left"></i>
								</button>
								<button
									className="button-icon button-icon--secondary button-icon--lg"
									onClick={() => nextTool(false)}
								>
									<i className="icon-arrow-right"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="container__50 hidden-lg">
					<div className="lootie-inside-carousel">
						{tools.map((item, index) => {
							return (
								item.id === toolSelected.id && item.anim && (
                                    item.anim && (
                                        <Player
                                            key={index}
                                            src={item.anim.localFile.publicURL}
                                            autoplay={true}
                                            keepLastFrame={true}
                                            background={'transparent'}
                                            className="inside-carousel"
                                        />
                                    )
                                ) 
							);
						})}
                        {tools.map((item, index) => {
                            return (
                                item.id === toolSelected.id && item.image && (
                                    item.image && (
                                        <GatsbyImage image={getImage(item.image.localFile)} alt={item.alternativeText} key={index} />
                                    )
                                )
                            )
                        })}
					</div>
				</div>
			</div>
		</div>
	);
};
