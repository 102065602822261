import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

export const ResponsiveImage = ({ image1440, image992, image768, image576 }) => {
	return (
		<>
			<div className="hidden-md">
				<GatsbyImage
					alt={image1440.alternativeText}
					image={getImage(image1440.localFile)}
				/>
			</div>

			<div className="show-md hidden-sm">
				<GatsbyImage
					alt={image992.alternativeText}
					image={getImage(image992.localFile)}
				/>
			</div>

			<div className="show-sm hidden-xs">
				<GatsbyImage
					alt={image768.alternativeText}
					image={getImage(image768.localFile)}
				/>
			</div>

			<div className="show-xs">
				<GatsbyImage
					alt={image576.alternativeText}
					image={getImage(image576.localFile)}
				/>
			</div>
		</>
	);
};
