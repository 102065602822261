import React from "react";

export const Container = ({children, title = false, text = false, flexStart = false, np = false, center = false, overflow = false, overflowImage = false , animation = ""}) => {
    const containerClass = ['container']
    if (title) {
        containerClass.push('container--title')
    }
    if (flexStart) {
        containerClass.push('flex-start')
    }
    if (np) {
        containerClass.push('container--np')
    }
    if (center) {
        containerClass.push('container--center')
    }
    if (text) {
        containerClass.length = 0
        containerClass.push('container__text')
    }
    if (overflow) {
        containerClass.length = 0
        containerClass.push('container__overflow')
    }
    if (overflowImage) {
        containerClass.length = 0
        containerClass.push('container__overflow__image')
    }
    return (<div className={containerClass.join(' ')} data-aos={animation} >{children}</div>)
}

export const Container50 = ({textCenter = false, animation = "", children}) => {
    const containerClass = ['container__50']
    if (textCenter) {
        containerClass.push('text--center')
    }

    return (<div className={containerClass.join(' ')} data-aos={animation}>{children}</div>)
}