import React, { useState } from 'react';
import { IsMobile } from '../../utils/media-queries';

export const Tabs = ({ tabs, small = false, pills = false}) => {
	const [activeTab, setActiveTab] = useState(tabs[0]);

	// TODO
    let tabsGroupClass = 'tabs'

	if (!pills && IsMobile()) {
		pills = true
	}

	if (pills) {
        tabsGroupClass += ' tabs--pills-big'
    }

	return (
		<>
			<div className={tabsGroupClass}>
				{tabs.map((tab) => (
					<>	
					{pills ?  (
						<h5
						className={`tabs__tab ${activeTab.title === tab.title ? 'tabs__tab--active' : ''}`}
						onClick={() => setActiveTab(tab)}
					>
						{tab.title}
					</h5>
					) : (
						small ?  (
							<h3
								className={`tabs__tab ${activeTab.title === tab.title ? 'tabs__tab--active' : ''}`}
								onClick={() => setActiveTab(tab)}
							>
								{tab.title}
							</h3>
						) : (
							<h2
								className={`tabs__tab ${activeTab.title === tab.title ? 'tabs__tab--active' : ''}`}
								onClick={() => setActiveTab(tab)}
							>
								{tab.title}
							</h2>
						)
					)}
					</>
				))}
			</div>
			<div>{activeTab.content}</div>
		</>
	);
};
