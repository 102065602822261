import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export const Stats = ({ stats, grid = 'sm-4', noAnimation = false }) => {
	let divStatClass = 'grid';
	if (grid) {
		divStatClass += `-${grid}`;
	}
	return (
		<div className={divStatClass}>
			{stats.map((stat, index) => {
				let animation = {};
				if (!noAnimation) {
					animation = { 'data-aos': 'zoom-in', 'data-aos-delay': 200 * index };
				}
				return (
					<div className="stat" key={index} {...animation}>
						<div className="title-xl">{stat.title}</div>
						<span>
							<ReactMarkdown children={stat.description} rehypePlugins={[rehypeRaw]} />
						</span>
					</div>
				);
			})}
		</div>
	);
};
